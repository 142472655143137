<template>
    <el-dialog
        class="editDialog"
        :append-to-body="true"
        width="600px"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"

    >

        <div slot="title" class="title">{{ options.id ? '编辑' : '新增' }}文件</div>

        <div class="content" v-loading="loading">
            <el-form label-width="6em" :model="form" :rules="rules" ref="form">

                <el-form-item label="地级" prop="level" ref="level">
                    <el-autocomplete
                        @keyup.enter.native="submit"
                        v-model="form.level"
                        :fetch-suggestions="levelQuerySearch"
                        placeholder="请输入地级"
                        @select="levelHandleSelect"
                    >
                        <div slot-scope="{item}">{{ item }}</div>
                    </el-autocomplete>
                </el-form-item>

                <el-form-item label="文件名称" prop="title" ref="title">
                    <el-input v-model="form.title" placeholder="请输入文件名称" @keyup.enter.native="submit"></el-input>
                </el-form-item>

                <el-form-item label="文件(pdf)" :prop="'attachmentVos[' + 0 + '].fileName'" prop="attachmentVos[0].fileName" :rules="rules.fileName" ref="fileName">
                    <el-input v-model="form.attachmentVos[0].fileName" placeholder="请选择文件" :readonly="true">
                        <Upload slot="append" accept="application/pdf" @upload="upload">
                            <el-button slot="button" :loading="file.loading">选择</el-button>
                        </Upload>
                    </el-input>
                </el-form-item>

            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>
import {mapState} from "vuex";
import mixinsForm from '@/mixins/form'
export default {
    components: {
        Upload: () => import("@/components/upload/Upload.vue"),
    },
    mixins: [mixinsForm],
    props: {},
    data() {
        return {
            form: {
                attachmentVos:[{
                    fileName:'',
                    fileAddress:'',
                }]
            },
            api:{
                save:this.$api['admin/investmentPolicy'].save,
            },
            rules: {
                level: [{ required: true, message: '请输入地级', trigger:['blur','change'] }],
                title: [{ required: true, message: '请输入文件名称', trigger:['blur','change'] }],
                fileName: [{ required: true, message: '请选择文件', trigger:['blur','change'] }],
            },
            file:{
                loading:false,
                fileName:'',
                fileAddress:'',
            }
        };
    },
    computed: {
        ...mapState({
            level: state => state.cache.data.level,
        })
    },
    watch: {
        file:{
            deep: true,
            handler: function (newVal){
                if(!newVal.loading){
                    this.form.attachmentVos = [{
                        fileName:newVal.fileName,
                        fileAddress:newVal.fileAddress,
                    }]
                }
            }
        },
    },
    created() {},
    methods: {
        upload(r){
            this.file = r[0];
        },
        levelQuerySearch(queryString, cb){
            if(queryString){
                var arr = []
                this.level.some(r=>{
                    if(r.includes(queryString)){
                        arr.push(r)
                    }
                })
                cb(arr);
            }else {
                cb(this.level);
            }
        },
        levelHandleSelect(item){
            this.form.level = item;
        },
        submit(){
            this.$refs.form.validate((valid,obj) => {
                if (valid) {
                    this.submitLoading = true;
                    this.api.save(this.form).then( res => {
                        this.$store.commit({
                            type: 'cache/ADD_DATA',
                            key:'level',
                            data:this.form.level,
                        });
                        this.submitLoading = false;
                        this.refresh();
                        this.$message.success("操作成功！");

                    }).catch(err=>{
                        this.submitLoading = false;
                        console.log(err);
                    })
                }else {
                    this.scrollView(obj)
                };
            });
        },
    },
};
</script>
