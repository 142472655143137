export default {
    components: {},
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        options: {
            type: Object,
            default:function(){
                return {}
            }
        },
        refresh: {
            type: Function,
            default: () => {}
        },
    },
    data() {
        return {
            showEditDialog: false,
            loading: true,
            submitLoading: false,
            form: {},
            api:{},
        };
    },
    watch: {
        isShow: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                this.showEditDialog = newVal;
                if(newVal){
                    this.init(this.options);
                    this.$nextTick(()=>{
                        this.$refs.form.clearValidate();
                    });
                }
            }
        },
    },
    created() {},
    methods: {
        init(options){
            this.form = options.deepClone();
            this.loading = false;
        },
        submit(){

            this.$refs.form.validate((valid,obj) => {
                if (valid) {

                    this.submitLoading = true;

                    this.api.save(this.form).then( res => {
                        this.submitLoading = false;
                        this.refresh();
                        this.$message.success("操作成功！");

                    }).catch(err=>{
                        this.submitLoading = false;
                        console.log(err);
                    })
                }else {
                    this.scrollView(obj)
                };
            });
        },
    },
};
